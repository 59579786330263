<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4">
      <v-toolbar flat>
        <v-toolbar-title>{{ $t("shelves") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-row justify="end">
          <edit-dialog v-if="$admin.can('category-update')"> </edit-dialog>
          <show-dialog v-if="$admin.can('category-view')"> </show-dialog>

          <v-dialog
            v-if="$admin.can('category-delete')"
            v-model="isVisibleDeleteDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title class="text-h5">{{
                $t("delete_confirmation_message")
              }}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeForm">{{
                  $t("cancel")
                }}</v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="destroyConfirm(editedCategory)"
                  >{{ $t("ok") }}</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- <v-dialog v-if="$admin.can('category-import')" v-model="isVisibleCSVDialog" >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" class="mx-5" v-bind="attrs" v-on="on">
                            {{ $t("import_csv")}}
                        </v-btn>
                    </template>
                    <v-form ref="csv_form" v-model="is_valid_csv" lazy-validation>
                        <v-card>
                            <v-card-title class="pb-4">
                                <span class="text-h3">{{ $t("categories_csv") }}</span>
                            </v-card-title>
                            <v-card-text class="pa-0 grey lighten-4">
                                <v-container>
                                    <v-card>
                                        <v-card-text>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-file-input dense outlined @change="onFileChanged($event)"  type="file" label="csv" prepend-icon="" append-icon="mdi-file"></v-file-input>
                                                </v-col>
                                            
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-container>
                            
                            </v-card-text>
                            <v-card-actions>
                                <small>{{$t("indicates_required_field")}}</small>
                                <v-spacer></v-spacer>
                                <v-btn color="red darken-1" text @click="closeForm()">
                                    {{ $t("close")}}
                                </v-btn>
                                <v-btn color="primary" text @click="uploadCSV(categories)" :disabled="!is_valid_csv" :loading="is_uploading">
                                    {{ $t("upload")}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog> -->
          <v-btn
            color="primary"
            @click="getCSV(options)"
            :loading="is_loading_csv"
            :disabled="is_loading_csv"
          >
            <v-icon color="white" class="mr-2">
              mdi-arrow-down-bold-circle-outline
            </v-icon>
            {{ $t("export_csv") }}
          </v-btn>
        </v-row>
      </v-toolbar>
      <v-treeview open-all dense hoverable :items="treeCategories">
        <template v-slot:append="{ item }">
          <v-chip v-if="item.code">
            {{ item.code }}
          </v-chip>
          <v-chip :color="getColor(item.status)" dark class="mx-15 my-2">
            {{ item.status }}
          </v-chip>

          <v-icon small class="mx-1" @click="show(item)"> mdi-eye </v-icon>
          <v-icon small class="mx-1" @click="edit(item)" v-if="item.level == 1">
            mdi-pencil
          </v-icon>
          <v-icon
            small
            class="mx-1"
            @click="destroy(item)"
            v-if="item.level == 1"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-treeview>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { validationRules } from "@/mixins/validationRules";
import { helpers } from "@/mixins/helpers";

export default {
  mixins: [validationRules, helpers],

  async mounted() {
    await this.$store.dispatch("categories/list");
    await this.$store.dispatch("categories/listRoots");
    await this.$store.dispatch("categories/listShelves");
  },

  components: {
    EditDialog: () => import("./shelves/dialogs/Edit.vue"),
    ShowDialog: () => import("./shelves/dialogs/Show.vue"),
  },
  computed: {
    ...mapGetters({
      isLoadingCategories: "categories/isLoadingCategories",
      treeCategories: "categories/treeCategories",
      rootCategories: "categories/listRoots",

      listStatuses: "statuses/listStatuses",
    }),
  },
  data() {
    return {
      is_valid_form: true,
      is_valid_csv: true,
      is_uploading: false,
      is_loading_csv: false,
      is_deleting_unused: false,
      isVisibleDeleteDialog: false,
      isVisibleCSVDialog: false,
      editedCategory: {},
      categories: {},
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("parent"),
          align: "start",
          value: "parent.name",
          width: "15%",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: false,
          value: "actions",
          width: "15%",
        },
      ],
    };
  },
  methods: {
    edit(item) {
      this.$store.dispatch("categories/openEditForm", item);
    },
    show(item) {
      this.$store.dispatch("categories/openShowForm", item);
    },

    destroy(item) {
      this.isVisibleDeleteDialog = true;
      this.editedCategory = Object.assign({}, item);
    },

    async destroyConfirm(item) {
      try {
        await this.$store.dispatch("categories/destroy", item);
        await this.$store.dispatch("categories/list");

        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.closeForm();
    },

    async destroyUnused() {
      this.is_deleting_unused = true;
      try {
        await this.$store.dispatch("categories/destroyUnused");
        await this.$store.dispatch("categories/list");

        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.is_deleting_unused = false;
    },

    closeForm() {
      this.isVisibleCSVDialog = false;
      this.isVisibleDeleteDialog = false;
      this.editedCategory = {};
    },

    async uploadCSV(categories) {
      if (this.$refs.csv_form.validate()) {
        try {
          this.is_uploading = true;
          await this.$store.dispatch("categories/uploadCSV", categories);
          this.$store.dispatch(
            "alerts/success",
            this.$t("created_successfully")
          );
          this.is_uploading = false;
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
          this.is_uploading = false;
        }
        this.is_uploading = false;
      }
    },

    async getCSV(option) {
      this.is_loading_csv = true;
      await this.$store
        .dispatch("categories/exportCSV", option)
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "categories.csv");
          document.body.appendChild(link);
          // window.open(link);
          link.click();
          this.is_loading_csv = false;
        })
        .catch((error) => {
          this.$store.dispatch("alerts/error", error.response?.statusText);
          this.is_loading_csv = false;
        });
    },

    onFileChanged(e) {
      this.categories.csv = e;
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>

<style lang="sass">
.v-treeview-node__root
  padding: 0 15px
  border-top: solid 1px #e1e1e1
</style>
